import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M480-160L160-480l320-320 42 42-248 248h526v60H274l248 248-42 42z" />
        </svg>
    )
}

export default SvgComponent
