import React, {Component} from "react";
import ModalComponent from "../atoms/ModalComponent";
import {Link} from "react-router-dom";
import {Button} from "../atoms/Button";
import LottieWrapper from "../atoms/LottieWrapper";
import hand from "../img/hand.json";


class GetAppModal extends Component {

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={'md'}
                toggle={this.props.closeModal}
                // headerToggle
            >
                <div className='w-full md:p-5'>
                    <h1 className={`text-xl md:text-3xl font-bold text-slate-900 mb-0 mt-0`}>
                        Download Goods today!
                    </h1>
                    <p className='text-lg text-slate-700 mt-3 mb-5'>
                        Download the iOS app to view more information about this NFT
                    </p>
                    <div className='row-ac-jc gap-3'>
                        <div className='relative w-min mt-4'>
                            <a href={'https://apps.apple.com/us/app/goods-your-web3-camera/id6449231324'}
                               target={'_blank'} rel={'noreferrer'} className='whitespace-nowrap'>
                                <Button>
                                    Download the App
                                </Button>
                            </a>
                            <div className='pointer-events-none absolute -top-8 -right-9 rotate-[-80deg] z-[10]'>
                                <LottieWrapper json={hand} width={80}/>
                            </div>
                        </div>
                        <Button onClick={this.props.closeModal} variant={'secondary'}>
                            Close
                        </Button>
                    </div>
                </div>
            </ModalComponent>
        )
    }

}


export default GetAppModal;
