import axios from './axios';


export const getPost = async (post_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id}
        };
        const res = await axios.get("/posts/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getComments = async (post_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id}
        };
        const res = await axios.get("/posts/comments", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}