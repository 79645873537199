import metamask from "../img/MetaMask_Fox.svg.png";
import coinbase from "../img/coin.png";
import walletconnect from "../img/a5169900-c66c-11e9-8592-33c7334dfd6d.png";
import {Component} from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from 'walletlink'
import {INFURAID, MAINNET_CHAIN_ID, RPC_URL} from "../utils/consts";
import {firebaseDatabase} from "../api/firebase";
import {getDatabase, ref, onValue, update} from "firebase/database";
import WordmarkWhite from "../icons/brand/WordmarkWhite";
import WordmarkColor from "../icons/brand/WordmarkColor";

const ethers = require("ethers");


// Initialize WalletLink
const walletLink = new WalletLink({
    appName: "SeshWorld",
    appLogoUrl: "SESH",
})

class Link extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: "",
            connected: false,
            mode: "walletlink",
            loading: false,
            codeValue: "",
            validCode: false,
            checkingCode: false,
            showError: false
        }
        this.provider = new WalletConnectProvider({
            infuraId: INFURAID, // Required
        });
        this.ethersProvider = undefined
        this.linkProvider = walletLink.makeWeb3Provider(RPC_URL, 1)
        this.defaultProvider = ethers.getDefaultProvider()
    }

    triggerProvider = async (mode) => {
        if (mode === "metamask") {
            if (window.ethereum) {
                console.log("CONNECTED TO ETHEREUM")
                this.provider = window.ethereum;
                const address = await this.provider.request({method: 'eth_requestAccounts'})
                console.log("ADDRESS", address)
                this.setState({
                    connected: true,
                    mode: "ethereum",
                    walletAddress: address[0].toString()
                })
                this.authenticateWallet(address[0].toString())
                // METAMASK CHECK CONNECTION
            } else {
                alert("No Metamask connection found! Please use wallet connect or try inside the metamask app.")
            }
        } else if (mode === "walletlink") {
            const connect = await this.linkProvider.enable();
            this.provider = this.linkProvider;
            this.setState({
                connected: true,
                walletAddress: connect[0].toString()
            })
            this.authenticateWallet(connect[0].toString())
        } else {
            try {
                const connect = await this.provider.enable();
                this.setState({
                    connected: true,
                    walletAddress: connect[0].toString()
                })
                this.authenticateWallet(connect[0].toString())

            } catch (err) {
                console.log("ERROR", err)
            }
        }

        const chainId = await this.provider.request({method: "eth_chainId"})
        if (chainId !== MAINNET_CHAIN_ID) {
            await this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: MAINNET_CHAIN_ID}]
            });
        }

    }


    handleCodeChange = (event) => {
        this.setState({codeValue: event.target.value, showError: false});
    }

    checkLinkCode = () => {
        this.setState({checkingCode: true})
        onValue(ref(firebaseDatabase, `codes/${this.state.codeValue}`), (snapshot) => {
            const data = snapshot.val()
            if (!!data) {
                this.setState({
                    validCode: true,
                    checkingCode: false
                })
            } else {
                this.setState({
                    errorCode: true,
                    checkingCode: false,
                    showError: true
                })
            }
            console.log("CODE", snapshot.val())
        });
    }

    authenticateWallet = async (walletAddress) => {

        update(ref(firebaseDatabase, 'codes/' + this.state.codeValue), {
            authenticated: true,
            walletAddress: walletAddress,
        });
    }

    render() {
        return (
            <div style={{minHeight: '100vh'}} className='col-ac-jc'>
                <div className='col-ac mt-24'>
                    <div className='row-ac-jc mb-40'>
                        <WordmarkColor className={'w-32 md:w-40'} style={{opacity: 1}}/>
                    </div>
                    <img src={require('../img/phones.png')} className='h-[25vh] md:h-[35vh] object-contain'/>
                    <h1 className={`${this.props.mobile ? 'title1' : 'title1'} mt-24 base-black-100 mb-0 mt-0 px-2`}>
                        {this.state.connected ?
                            "You're all set! Check back on the app."
                            :
                            this.state.validCode ?
                                'Pick your connection method from the list below'
                                :
                                'Enter the code from your app to connect your wallet!'
                        }
                    </h1>
                    <p className='body2 base-black-50 mb-40 px-2'>
                        {this.state.connected ?
                            "If you're having trouble, try relinking with a different method."
                            :
                            this.state.validCode ?
                                'EVOLVE uses third parties to link your account, and does not store any information.'
                                :
                                'You should see a code in your app. Enter it below, then select the appropriate option to link your wallet.'
                        }
                    </p>
                </div>
                {!this.state.connected &&
                    <div className='relative px-2'>
                        {!this.state.validCode &&
                            <div className='flex flex-col md:flex-row items-center gap-2'>
                                <input
                                    type="text"
                                    placeholder={'Your Code'}
                                    maxLength={6}
                                    className='bg-base-black-10 base-black-70 h-14 w-full md:w-min'
                                    value={this.state.codeValue}
                                    onChange={this.handleCodeChange}
                                />
                                <button onClick={this.checkLinkCode}
                                        className={'primary-button-lg body1-bold'}>
                                    {this.state.checkingCode ?
                                        'Checking Code...'
                                        :
                                        'Submit'
                                    }
                                </button>
                            </div>
                        }
                        {this.state.showError &&
                            <div className='col-ac-jc mv-24 absolute' style={{left: 0, right: 0, bottom: -58}}>
                                <p style={{color: '#ec5f4e'}} className='body1'>
                                    Invalid code, please try again.
                                </p>
                            </div>
                        }
                    </div>
                }
                {(this.state.validCode && !this.state.connected) &&
                    <div className='row-ac-jc'
                         style={{flexWrap: "wrap"}}>
                        <div className='wallet-option' onClick={() => this.triggerProvider("metamask")}
                             style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                            <img src={metamask}
                                 style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                 className='mt-1'/>
                            <p className='mv-8 mb-0 body1 base-black-70'
                               style={{fontSize: this.props.mobile ? 16 : 24}}>Metamask</p>
                            <p className='body3 mt-0 mobile-subname text-center base-black-50'
                               style={{fontSize: this.props.mobile && 10}}>Connect to your Metamask Wallet</p>
                        </div>


                        <div className='wallet-option mh-8' onClick={() => this.triggerProvider("walletlink")}
                             style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                            <img src={coinbase}
                                 style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                 className='mt-1 br-16'/>
                            <p className='mv-8 mb-0 body1 base-black-70'
                               style={{fontSize: this.props.mobile ? 16 : 24}}>Coinbase
                                Wallet</p>
                            <p className='body3 mt-0 mobile-subname text-center base-black-50'
                               style={{fontSize: this.props.mobile && 10}}>Connect to your Coinbase Wallet</p>
                        </div>

                        <div className='wallet-option' onClick={() => this.triggerProvider("walletconnect")}
                             style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                            <img src={walletconnect}
                                 style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                 className='mt-1'/>
                            <p className='mv-8 mb-0 body1 base-black-70'
                               style={{fontSize: this.props.mobile ? 16 : 24}}>WalletConnect</p>
                            <p className='body3 mt-0 mobile-subname text-center base-black-50'
                               style={{fontSize: this.props.mobile && 10}}>Scan with WalletConnect to connect</p>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Link
