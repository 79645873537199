import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 784.47 303"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={-71.07}
                    y1={190.9}
                    x2={869}
                    y2={190.9}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#6bbdf3" />
                    <stop offset={0.12} stopColor="#6fb4f1" />
                    <stop offset={0.32} stopColor="#7c9cec" />
                    <stop offset={0.58} stopColor="#9276e4" />
                    <stop offset={0.87} stopColor="#af41da" />
                    <stop offset={1} stopColor="#bf26d5" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={-71.07}
                    y1={157.34}
                    x2={869}
                    y2={157.34}
                    xlinkHref="#a"
                />
                <linearGradient
                    id="c"
                    y1={157.34}
                    x2={869}
                    y2={157.34}
                    xlinkHref="#a"
                />
                <linearGradient
                    id="d"
                    y1={117.93}
                    x2={869}
                    y2={117.93}
                    xlinkHref="#a"
                />
                <linearGradient
                    id="e"
                    x1={-71.07}
                    y1={157.34}
                    x2={869}
                    y2={157.34}
                    xlinkHref="#a"
                />
            </defs>
            <path
                d="M142.5 82.32h-22.57c-5.37 0-9.84 3.96-10.63 9.12-7.37-6.02-19.27-12.62-36.03-12.62C32.18 78.81 0 113.3 0 157.34s32.18 78.52 73.27 78.52c15.25 0 26.32-5.35 33.28-10.28v3.27c0 19.48-11.61 29.78-33.57 29.78-17.35 0-31.37-9.7-38.19-15.48a10.762 10.762 0 00-8.18-2.48 10.79 10.79 0 00-7.41 4.29L5.26 263.82c-3.37 4.57-2.61 11 1.75 14.64 8.81 7.38 32.89 24.53 65.97 24.53 37.03 0 80.27-19.72 80.27-75.31V93.07c0-5.93-4.82-10.75-10.75-10.75zm-35.95 94.11c-2.52 3.85-11.25 15.06-27.15 15.06-19.51 0-32.11-13.41-32.11-34.15s12.6-34.15 32.11-34.15c16.11 0 24.69 11.19 27.15 15.02v38.22z"
                fill="url(#a)"
            />
            <path
                d="M239.21 78.81c-44.69 0-79.69 34.49-79.69 78.52s35 78.52 79.69 78.52 79.69-34.49 79.69-78.52-35-78.52-79.69-78.52zm0 112.67c-22.26 0-32.4-17.7-32.4-34.15s10.14-34.15 32.4-34.15 32.4 17.7 32.4 34.15-10.14 34.15-32.4 34.15z"
                fill="url(#b)"
            />
            <path
                d="M404.54 78.81c-44.69 0-79.69 34.49-79.69 78.52s35 78.52 79.69 78.52 79.69-34.49 79.69-78.52-35-78.52-79.69-78.52zm0 112.67c-22.26 0-32.4-17.7-32.4-34.15s10.14-34.15 32.4-34.15 32.4 17.7 32.4 34.15-10.14 34.15-32.4 34.15z"
                fill="url(#c)"
            />
            <path
                d="M633.83 0h-25.2c-5.93 0-10.75 4.82-10.75 10.75v78.53c-7.03-5.02-18.16-10.47-33.28-10.47-41.08 0-73.27 34.49-73.27 78.52s32.18 78.52 73.27 78.52c17.04 0 28.85-6.49 36.07-12.39.89 5.04 5.3 8.89 10.59 8.89h22.57c5.93 0 10.75-4.82 10.75-10.75V10.75c0-5.93-4.82-10.75-10.75-10.75zm-35.95 176.46c-2.46 3.83-11.03 15.03-27.15 15.03-19.51 0-32.11-13.41-32.11-34.15s12.6-34.15 32.11-34.15c16.11 0 24.69 11.19 27.15 15.02v38.26z"
                fill="url(#d)"
            />
            <path
                d="M732.85 138.2c-18.96-4.03-28.07-6.12-28.07-9.76s7.91-7.3 19.26-7.3 22.28 5.37 27.56 8.56c4.88 2.96 11.22 1.58 14.44-3.15l12.06-17.69c1.65-2.42 2.24-5.34 1.66-8.2a10.67 10.67 0 00-4.71-6.89c-8.68-5.59-26.85-14.96-51-14.96-42.47 0-65.39 25.27-65.39 49.04 0 24.94 18.84 41.59 56.02 49.5 21.82 4.55 22.79 5.8 22.79 9.46 0 3.23-7.81 6.71-20.43 6.71-14.9 0-27.76-9.28-34.09-14.81-2.23-1.95-5.19-2.9-8.13-2.6-2.94.29-5.65 1.8-7.46 4.14l-13.59 17.65c-3.39 4.41-2.83 10.78 1.27 14.5 7.78 7.05 29.5 23.44 63.17 23.44 37.78 0 66.26-20.83 66.26-48.46 0-33.59-24.81-43.5-51.62-49.21z"
                fill="url(#e)"
            />
        </svg>
    )
}

export default SvgComponent
