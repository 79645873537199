// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlkM8Ns9AIh-fkQ2FkgIYPjbenH478iXU",
  authDomain: "evolve-1bf80.firebaseapp.com",
  databaseURL: "https://evolve-1bf80-default-rtdb.firebaseio.com",
  projectId: "evolve-1bf80",
  storageBucket: "evolve-1bf80.appspot.com",
  messagingSenderId: "247251721361",
  appId: "1:247251721361:web:0d70f62152bbd0a01c3330",
  measurementId: "G-V9N5KBZXG4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firebaseDatabase = getDatabase(app);

