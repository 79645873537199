import React, {PureComponent} from "react";
import CollapseComponent from "./CollapseComponent";
import {formatUsername} from "../utils/strings";
import ProfilePicture from "./ProfilePicture";
import moment from "moment";
import SvgLike from "../icons/SvgLike";
import SvgChat from "../icons/SvgChat";
import { withRouter } from 'react-router-dom';

class GalleryItem extends PureComponent {
    state = {
        hovering: false
    }

    onHover = () => {
        this.setState({hovering: true})
    }

    onLeave = () => {
        this.setState({hovering: false})
    }

    navigateToPost = () => {
        this.props.history.push(`/post/${this.props.post.post_id}`, {post: this.props.post,});
    };

    render() {
        const post = this.props.post
        const iconSize = 32
        const nftImgSize = this.props.mobile ? 32 : 40
        return (
            <div className={`post ${this.state.hovering && 'post-hovering'}`} onMouseEnter={this.onHover} onMouseLeave={this.onLeave} onClick={this.navigateToPost}>
                <div className='post-cover-gradient br-32'/>
                <div className="post">
                    <img  src={post.preview_pic} alt={`preview-${post.post_id}`}
                         className='br-32 w-100p'/>
                    <div className='base-white-100 post-nft-banner row-ac'>
                        <img alt={`preview-${post.nft.collection_name}`} src={post.nft.image} className='br-8' style={{width: nftImgSize, height: nftImgSize}}/>
                        <div className='body2-bold base-white-100 ml-8 w-100p'>
                            <p className='m-0 ellipsis mr-40'>
                                {post.nft.name || 'No Name'}
                            </p>
                            <p className='m-0 body3 base-white-70 ellipsis mr-40'>
                                {post.nft.collection_name}
                            </p>
                        </div>
                    </div>
                    <div className='base-white-100 post-info'>
                        <div className='row-ae-jb mb-24'>
                            <div className='mr-12'>
                                <div className='row-ac mb-8'>
                                    <ProfilePicture username={post.creator}/>
                                    <div className='body2-bold gradient-text base-white-30 ml-8'>
                                        {formatUsername(post.creator)}
                                    </div>
                                        <span className='body3 ml-6 base-white-30' style={{marginTop:2}}>
                                    {moment(post.created_at).fromNow()}
                                </span>
                                </div>
                                <p className={post.content ? 'base-white-100 m-0 body2' : 'base-white-30 m-0 body2'}>{post.content || 'No caption'}</p>
                            </div>
                            <div className='base-white-100 col-ac'>
                                <div className='col-ac mb-12 pointer'>
                                    <SvgLike width={iconSize} height={iconSize}/>
                                    <p className='m-0 body3-bold'>
                                        {Object.keys(post?.likes).length}
                                    </p>
                                </div>
                                <div className='col-ac pointer'>
                                    <SvgChat width={iconSize} height={iconSize}/>
                                    <p className='m-0 body3-bold'>
                                        {post.comments}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <CollapseComponent isOpen={this.state.hovering}>
                            <button className='primary-button w-100p body2-bold mb-24 base-black-100'>
                                View
                            </button>
                        </CollapseComponent>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(GalleryItem)
