import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva} from "class-variance-authority"

const buttonVariants = cva(
    `inline-flex flex flex-row items-center justify-center text-xlg font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
    {
        variants: {
            variant: {
                default: "bg-[#6fb5f1] hover:bg-[#65a5db] text-white",
                green: "bg-green-500 hover:bg-green-600 text-black",
                purple: "bg-purple-500 hover:bg-purple-600 text-black",
                destructive:
                    "bg-red-500 hover:bg-red-600 text-white",
                outline:
                    "border border-input bg-background text-slate-500 hover:bg-accent hover:text-accent-foreground dark:text-darkAccent-light",
                secondary:
                    "bg-slate-500/30 text-slate-900 hover:bg-slate-600/30",
                ghost: "text-white/50 hover:text-white",
                ghostOverlay: "text-white hover:bg-neutral-200/40",
                link: "text-blue-500 font-lighter",

            },
            size: {
                default: "h-[48px] px-5 sm:text-sm",
                sm: "h-8 px-3 text-sm md:text-[16px]",
                md: "h-10 px-4 text-md",
                lg: "h-12 px-5 text-lg",
                icon: "h-10 w-10",
                iconsm: "h-8 w-8 text-sm",
            },
            radius: {
                default: 'rounded-full',
                sm: 'rounded-sm',
                lg: 'rounded-lg',
                full: 'rounded-full',
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
            radius: "default",
        },
    }
)


const Button = React.forwardRef(
    ({className, variant, loading, radius, size, asChild = false, ...props}, ref) => {
        const Comp = asChild ? Slot : "button"
        if (loading) {
            return (
                <Comp
                    className={buttonVariants({variant, size, radius, className}) + ' rounded-full'}
                    ref={ref}
                    {...props}
                >
                    {/*<Spinner dark/>*/}
                </Comp>
            )
        }
        return (
            <Comp
                className={buttonVariants({variant, size, radius, className}) + ' rounded-full'}
                ref={ref}
                {...props}
            >
                <div className='flex flex-row items-center justify-center gap-1 mb-0'>
                    {props.children}
                    {props.icon}
                </div>
            </Comp>
        )
    }
)
Button.displayName = "Button"

export {Button, buttonVariants}
