import './App.css';
import {Component} from "react";
import '../src/styles/Formatting.css';
import '../src/styles/ColorStyles.css'
import '../src/styles/Inputs.css'
import '../src/styles/Navbar.css'
import '../src/styles/TextStyles.css'
import '../src/styles/ButtonStyles.css'
import '../src/styles/Components.css'
import '../src/styles/Gallery.css'
import '../src/styles/ModalComponent.css'
import {BrowserRouter} from 'react-router-dom';
import {getAllPosts} from "./api/feeds";
import {wait} from "@testing-library/user-event/dist/utils";
import Switch from "react-router-dom/es/Switch";
import Home from "./pages/Home";
import Route from "react-router-dom/es/Route";
import Post from "./pages/Post";
import WordmarkWhite from "./icons/brand/WordmarkWhite";
import ScrollToTop from "./atoms/ScrollToTop";
import './fonts/Cinderblock-75.woff'
import Privacy from "./pages/Privacy";
import ConnectWalletModal from "./modals/ConnectWalletModal";
import {Link} from "react-router-dom";
import LinkComponent from "./pages/Link";

import {Buffer} from "buffer";
import WordmarkColor from "./icons/brand/WordmarkColor";

window.Buffer = window.Buffer || Buffer;

class App extends Component {
    state = {
        mobile: false,
        tablet: false,
        posts: [],
        postsLoading: true
    }

    componentDidMount = async () => {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));

        const posts = await getAllPosts();
        this.setState({posts: posts.posts})
        wait(250).then(() => {
            this.setState({postsLoading: false})
        })
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth > 760 && window.innerWidth <= 1200)
        });
    }

    render() {
        return (
            <BrowserRouter>
                <ScrollToTop/>
                <div className='font-body'>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/post/:postId" component={Post}/>
                        <Route path="/link" component={LinkComponent}/>
                        <Route path="/invest"
                               render={() => {
                                   window.location.href = "https://docs.google.com/presentation/d/e/2PACX-1vReaZhKMSobRnB51skcjYgsgLBGVarL1PMmzO0do9I6AJwNGBSYwNh2InQQQ3HDacZk6oTsiq2CrSgV/pub?start=false&loop=false&delayms=2000";
                                   return <Home/>;
                               }}
                        />
                        <Route path="/privacy" component={Privacy}/>
                    </Switch>
                    <div className='absolute cover-parent'
                         style={{
                             backgroundImage: 'linear-gradient(193deg, #B03ED940, transparent, transparent)',
                             zIndex: -1
                         }}
                    />
                    <div className='col-plain mt-32 mb-5'>
                        <div className='w-100p bg-base-black-10 mb-32' style={{height: 1}}/>
                        <div className='flex flex-row items-center flex-wrap gap-y-8 justify-between px-5 md:px-12'>
                            <div className='row-ac-jc'>
                                <Link to={'/'}>
                                    <WordmarkColor width={100} style={{opacity: 1}}/>
                                </Link>
                            </div>
                            <div
                                className='flex text-sm text-black opacity-50 mb-2 pt-3 flex-row items-center gap-2 text-center justify-center'>
                                <Link to={'/privacy'} className={'hover:text-slate-800 hover:underline'}>
                                    Privacy Policy
                                </Link>
                                -
                                <a href="mailto:hello@blnkdigital.com"
                                   className={'hover:text-slate-800 hover:underline'}>
                                    <p>
                                        Contact:<span className={'text-semibold'}>hello@blnkdigital.com</span>
                                    </p>
                                </a>
                            </div>
                        </div>
                        <p className='mx-auto ml-5 md:ml-auto text-xs text-black opacity-50 pb'>
                            Powered by BLNK - Built by EVOLVE
                        </p>
                    </div>
                </div>
            </BrowserRouter>
        )
    }
}

export default App
