import React, {PureComponent} from "react";
import {colorsFromString} from "../utils/strings";

class ProfilePicture extends PureComponent {
    state = {
        imageError: false,
    };

    render() {
        const colors = colorsFromString(this.props.username);
        if (!this.state.imageError) {
            return (
                <img 
                    src={`https://firebasestorage.googleapis.com/v0/b/evolve-1bf80.appspot.com/o/users%2Fprofile_picture%2F${this.props.username}.jpeg?alt=media`}
                    style={{
                        width: this.props.size || 24,
                        height: this.props.size || 24,
                        borderRadius: 100,
                        aspectRatio:1,
                    }}
                    onError={(err) => {
                        const imageError = !!err;
                        this.setState({imageError});
                    }}
                />
            )
        }
        return (
            <div style={{
                width: this.props.size || 24,
                height: this.props.size || 24,
                borderRadius: 100,
                aspectRatio:1,
                background: `linear-gradient(12deg, ${colors[1]}, ${colors[2]})`
            }}>
            </div>
        )
    }
}

export default ProfilePicture
