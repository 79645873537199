import React, {PureComponent} from 'react';
import {wait} from "@testing-library/user-event/dist/utils";
import ProfilePicture from "../components/ProfilePicture";
import {formatUsername} from "../utils/strings";
import moment from "moment/moment";
import SvgLike from "../icons/SvgLike";
import SvgArrowBack from "../icons/SvgArrowBack";
import {withRouter} from "react-router-dom";
import {getComments, getPost} from "../api/posts";
import SvgChat from "../icons/SvgChat";
import ReactPlayer from 'react-player'
import GetAppModal from "../modals/GetAppModal";
import {Button} from "../atoms/Button";
import LottieWrapper from "../atoms/LottieWrapper";
import hand from "../img/hand.json";

class Post extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            appModalVisible: false,
            post: {},
            postLoading: true,
        };
    }

    componentDidMount = async () => {
        const post = this.props.location.state?.post || (await getPost(this.props.match.params.postId)).post;
        this.setState({post: post, postLoading: false})
        const comments = await getComments(post.post_id);
        this.setState({comments: comments.comments})
        wait(250).then(() => {
            this.setState({commentsLoading: false})
        })
    }

    goBack = () => {
        this.props.history.push('/')
        // TODO: handle arrive at post directly from url
        // this.props.history.length <= 2 ? this.props.history.push('/') : this.props.history.goBack()
    }

    openAppModal = () => {
        this.setState({appModalVisible: true})
    }

    closeAppModal = () => {
        this.setState({appModalVisible: false})
    }

    render() {
        const post = this.state.post;
        const nftImgSize = this.props.mobile ? 32 : 48
        return (
            this.state.postLoading ? null :
                <div className='relative'>
                    <SvgArrowBack onClick={this.goBack}
                                  className={'base-black-50 w-5 h-5 md:w-9 md:h-9 h-base-black-100 absolute pointer'}
                                  style={{top: 24, left: 24}}/>
                    <div className='page-h-padding flex flex-col md:flex-row items-center justify-center'>
                        <div className='md:mt-12' style={{minHeight: "20vh"}}>
                            <div className='hidden md:block'>
                                <ReactPlayer
                                    url={post.video_url}
                                    className='br-32 shadow video object-cover'
                                    playing={true}
                                    loop={true}
                                    height={'90vh'}
                                    style={{aspectRatio: 0.46}}
                                />
                            </div>
                            <div className='block mt-10 md:hidden rounded-lg overflow-hidden shadow'>
                                <ReactPlayer
                                    url={post.video_url}
                                    className='shadow video'
                                    playing={true}
                                    loop={true}
                                    muted={true}
                                    style={{aspectRatio: 0.46}}
                                />
                            </div>
                        </div>
                        <div
                            className='base-black-100 flex flex-col flex-1 w-full mt-10 md:mt-0 md:ml-40 md:max-w-[30vw]'>
                            <div className='row-ae flex flex-1' style={{marginTop: -8}}>
                                <div style={{marginBottom: 6, marginRight: 16}}>
                                    <div className='hidden md:block'>
                                        <ProfilePicture size={60} username={post.creator}/>
                                    </div>
                                    <div className='block md:hidden'>
                                        <ProfilePicture size={32} username={post.creator}/>
                                    </div>
                                </div>
                                <p onClick={this.openAppModal}
                                   className='pointer flex flex-1 heading1 cinderblock m-0 base-black-100'
                                   style={{fontWeight: "normal"}}>
                                    {formatUsername(post.creator)}
                                </p>
                            </div>
                            <p className={post.content ? 'base-black-100 mt-12 mb-0 body1' : 'base-black-30 mt-12 mb-0 body1'}>{post.content || 'No caption'}</p>
                            <span className='body3 base-black-30 mt-0' style={{marginBottom: 2}}>
                                    {moment(post.created_at).fromNow()}
                                </span>
                            <p className='body3-bold base-black-50 uppercase mt-32 mb-8'>
                                NFT
                            </p>
                            <div onClick={this.openAppModal}
                                 className=' pointer row-ac ph-8 pv-8 bg-base-black-4 br-12 mb-12 flex-1'>
                                <img alt={'nft'} src={post.nft.image} className='br-8'
                                     style={{width: nftImgSize, height: nftImgSize}}/>
                                <div className='body2-bold base-black-100 ml-8 w-90p'>
                                    <p className='m-0 ellipsis mr-40 mb-4'>
                                        {post.nft.name || 'No Name'}
                                    </p>
                                    <p className='m-0 body3 base-black-70 ellipsis mr-40'>
                                        {post.nft.collection_name}
                                    </p>
                                </div>
                            </div>
                            <div className='relative w-min mt-4'>
                                <a href={'https://apps.apple.com/us/app/goods-your-web3-camera/id6449231324'}
                                   target={'_blank'} rel={'noreferrer'} className='whitespace-nowrap'>
                                    <Button>
                                        View inside app
                                    </Button>
                                </a>
                                <div className='pointer-events-none absolute -top-8 -right-9 rotate-[-80deg] z-[10]'>
                                    <LottieWrapper json={hand} width={80}/>
                                </div>
                            </div>
                            <div onClick={this.openAppModal} className='pointer row-ac mt-32 mb-8'>
                                <SvgLike className={'base-black-50'} width={20} height={20}/>
                                <p className='body3-bold base-black-50 uppercase m-0 ml-8' style={{marginBottom: 3}}>
                                    {Object.keys(post?.likes).length} Like{Object.keys(post?.likes).length !== 1 && 's'}
                                </p>
                            </div>
                            <div className='row-ac mt-32 mb-8'>
                                <SvgChat className={'base-black-50'} width={20} height={20}/>
                                <p className='body3-bold base-black-50 uppercase m-0 ml-8' style={{marginBottom: 3}}>
                                    {this.state.comments.length} Comment{this.state.comments.length !== 1 && 's'}
                                </p>
                            </div>
                            {this.state.commentsLoading ?
                                <div className='col-ac-jc' style={{width: 300}}>
                                    <p className='m-0 body4 base-black-30 mv-32'>
                                        Loading
                                    </p>
                                </div>
                                :
                                this.state.comments.length === 0 ?
                                    <div className='col-ac-jc md:w-[300px]'>
                                        <p className='m-0 body4  base-black-30 mv-32'>
                                            No comments yet
                                        </p>
                                    </div>
                                    :
                                    <div className='col-plain' style={{width: 300}}>
                                        {this.state.comments.slice(0, 3).map(comment =>
                                            <div onClick={this.openAppModal}
                                                 className={'pointer base-black-100 ph-8 pv-8 bg-base-black-4 br-12 mb-12 row-jb'}>
                                                <div className='row-plain'>
                                                    <ProfilePicture username={comment.creator}/>
                                                    <div className='col-plain ml-8'>
                                                        <div className='row-ac'>
                                                            <p className='m-0 body3-bold gradient-text base-black-30'>
                                                                {formatUsername(comment.creator)}
                                                            </p>
                                                            <p className='m-0 ml-8 body3 base-black-30'>
                                                                {moment(comment.created_at).fromNow()}
                                                            </p>
                                                        </div>
                                                        <p className='m-0 body1 base-black-100'>
                                                            {comment.content}
                                                        </p>
                                                        <p className='m-0 body4 base-black-30'>
                                                            {Object.keys(comment.likes).length} Like{Object.keys(comment.likes).length !== 1 && 's'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.comments.length > 3 &&
                                            <div className='col-ac-jc body2 primary'>
                                                See more in the app!
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                    <GetAppModal
                        isOpen={this.state.appModalVisible}
                        closeModal={this.closeAppModal}
                    />
                </div>
        );
    }
}

export default withRouter(Post)

