import {useState, useMemo} from 'react';
import Lottie from "react-lottie";
import VisibilitySensor from 'react-visibility-sensor';

function LottieWrapper({json, width, height, className, speed, loop, alwaysVisible}) {
    const [visible, setVisible] = useState(true);

    const defaultOptions = useMemo(() => {
        return {
            loop: loop,
            autoplay: true,
            animationData: json,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
    }, [loop, json]);

    const handleVisibilityChange = (visible) => {
        setVisible(alwaysVisible || visible);
    }

    return (
        <VisibilitySensor
            onChange={handleVisibilityChange}
            partialVisibility={true}
            offset={{top:32, bottom:32}}
            scrollCheck
        >
            <div className={className}>
                {visible ?
                    <Lottie
                        speed={speed}
                        options={defaultOptions}
                        width={width}
                        height={height}
                    />
                    : <div style={{height: '300px', width: '100%'}}/>
                }
            </div>
        </VisibilitySensor>
    )
}

export default LottieWrapper
