export const formatUsername = (username) => {
    return username.slice(0, 4) + '...' + username.slice(-4)
}



const COLORS = [
    '#60FEEF',
    '#52AAF5',
    "#A16AE8",
    '#65e397',
    '#cc93e2'
]

export function wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}


export const colorsFromString = str => {
    const colors = [];
    if (!str) {
        colors.push(COLORS[0]);
    } else {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const indices = [];
        while (indices.length < 3) {
            const index = (Math.abs(hash) + indices.length) % COLORS.length;
            if (!indices.includes(index)) {
                indices.push(index);
                colors.push(COLORS[index]);
            }
        }
    }
    return colors;
};
