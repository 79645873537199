import React, {PureComponent} from 'react';

class FadeInOnScroll extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            loading: this.props.loading || false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        if (this.props.loading) {
            return
        } else {
            if (!this.props.triggerImmediately) {
                window.addEventListener('scroll', this.handleScroll);
            } else {
                this.triggerAnim()
            }
        }
    }

    triggerAnim = () => {
        if (this.props.delay) {
            setTimeout(() => {
                this.setState({
                    isVisible: true
                });
            }, this.props.delay);
        } else {
            this.setState({
                isVisible: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading && !this.props.loading) {
            this.triggerAnim()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const element = this.node;
        const isVisible = this.isInViewport(element);

        if (isVisible) {
            this.triggerAnim()
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

        return (vertInView && horInView);
    }

    render() {
        const {children} = this.props;
        const {isVisible} = this.state;
        return (
            <div
                className={`fade-in-on-scroll ${isVisible ? 'visible' : ''}`}
                style={this.props.style}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {children}
            </div>
        );
    }
}

export default FadeInOnScroll;
