import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M80-80v-800h800v640H240L80-80zm60-220h680v-520H140v520zm0 0v-520 520z" />
        </svg>
    )
}

export default SvgComponent
