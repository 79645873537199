import React, {PureComponent} from 'react';
import GalleryItem from "../components/GalleryItem";
import {getAllPosts} from "../api/feeds";
import {wait} from "@testing-library/user-event/dist/utils";
import {GridLoader} from "react-spinners";
import AnimateInOnScroll from "../atoms/AnimateInOnScroll";
import WordmarkColor from "../icons/brand/WordmarkColor";
import Select from "react-select";
import {Button} from "../atoms/Button";
import LottieWrapper from "../atoms/LottieWrapper";
import hand from '../img/hand.json'

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            options: [],
            postsLoading: true,
            selectedOption: "all",
        };
    }

    componentDidMount = async () => {

        const posts = await getAllPosts();
        const collections = [
            {value: 'all', label: 'All Collections'},
            ...([...new Set(posts.posts.map(p => p.nft.collection_name))].map(e => {
                return {value: e, label: e}
            }))
        ]
        this.setState({posts: posts.posts, options: collections})
        wait(250).then(() => {
            this.setState({postsLoading: false})
        })
    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption: selectedOption.value});
    };


    render() {
        const {selectedOption} = this.state;
        return (
            <div className='relative'>
                <div
                    className='ph-24 sticky top-0 z-[2] bg-white pt-24 pb-24 flex flex-col md:flex-row gap-3 items-center justify-between '
                    style={{borderBottom: '1px solid #00000020'}}>
                    <WordmarkColor style={{maxHeight: 60}}/>
                    <div className='flex flex-row gap-3'>
                        <div className='relative hidden md:block'>
                            <a href={'https://apps.apple.com/us/app/goods-your-web3-camera/id6449231324'}
                               target={'_blank'} rel={'noreferrer'}>
                                <Button>
                                    Download the App
                                </Button>
                            </a>
                            <div className='pointer-events-none absolute -top-8 -right-9 rotate-[-80deg] z-[10]'>
                                <LottieWrapper json={hand} width={80}/>
                            </div>
                        </div>
                        <Select
                            value={selectedOption}
                            placeholder={'Sort collections'}
                            onChange={this.handleChange}
                            options={this.state.options}
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                    </div>
                </div>
                {this.state.postsLoading ?
                    <div className='col-ac-jc' style={{height: '80dvh'}}>
                        <GridLoader color={'#00000020'}/>
                    </div>
                    :
                    <div className="post-feed grid-container" style={{minHeight: '80vh'}}>
                        {/* eslint-disable-next-line array-callback-return */}
                        {this.state.posts.filter(e => this.state.selectedOption === 'all' || e.nft.collection_name === this.state.selectedOption).map((post, i) => {
                            if (post.preview_pic) {
                                return (
                                    <AnimateInOnScroll triggerImmediately delay={(70 * i) + 1}>
                                        <GalleryItem post={post} key={post.post_id}/>
                                    </AnimateInOnScroll>
                                )
                            }
                        })}
                    </div>
                }
                <div className='fixed md:hidden bottom-4 mx-2 right-0 left-0'>
                    <a href={'https://apps.apple.com/us/app/goods-your-web3-camera/id6449231324'}
                       target={'_blank'} rel={'noreferrer'} className='w-full'>
                        <Button className='w-full'>
                            Download the App
                        </Button>
                    </a>
                    <div className='pointer-events-none absolute -top-8  right-2 rotate-[-80deg] z-[10]'>
                        <LottieWrapper json={hand} width={80}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
